/* General Styling */
* {
  box-sizing: border-box;
}

body {
  font-size: 62.5%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  margin: 0;
}

.lightMode {
  background: #6495ed;
  color: #f4f4f4;
}

.lightMode h1, h2, h3, h4, p, ul, li, a {
  color: #f4f4f4;
}

.darkMode {
  background: #444444;
  color: #f4f4f4
}

button {
  background: none;
  border: 2px solid #f4f4f4;
  padding: 1%;
  color: #f4f4f4;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.1em;
}

button:hover {
  border: 2px dashed #f4f4f4;
}

/* Media Queries */

/* @media (max-width: 800px) {
  .navbar {
   
  }
} */