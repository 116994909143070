.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.5em;
    background-color: black;
    padding: 1% 0%;
}

.footer a:link {
    background-color: transparent;
}

.footer a:visited {
    background-color: transparent;
    text-decoration: none;
}