.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.3em;
    background-color: black;
    padding: 1%;
}

.navbar h1 {
    font-size: 2.2em;
}

.navbar button {
    height: 40%;
}
