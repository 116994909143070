.contact {
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    font-size: 1.5em;
    text-align: center;
    text-shadow: 1px 1px black;
}

.SMlinks {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    margin: 1% 5%;
}

.contact a:link {
    background-color: transparent;
}

.contact a:visited {
    background-color: transparent;
    text-decoration: none;
}

.contact a:hover {
    text-shadow: 2px 2px 2px black;
}