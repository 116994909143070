.aboutImage {
    background: linear-gradient(
        rgba(0, 0, 0, 0.5), 
        rgba(0, 0, 0, 0.5)), 
        url("https://images.unsplash.com/photo-1587620962725-abab7fe55159?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80");
    height: 40vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aboutInfo {
    font-size: 1.7em;
    font-weight: bold;
    text-align: center;
    color: white;
    text-shadow: 1px 2px black;
}

.about p {
    margin-bottom: 3vh;
}

.about h2 {
    padding-top: 6%;
}

.aboutLink {
    text-decoration: none;
    color: white;
    border: 1px solid #5882cf;
    padding: 1%;
    background: #5882cf;
    border-radius: 12px;
    text-shadow: none;
}

.aboutLink:hover {
    color: #5882cf;
    background: white;
}
