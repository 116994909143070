.projects {
    margin-top: 1%;
    font-size: 1.5em;
    background-color: black;
    color: white;
    padding: 1%;
}

.projects h2 {
    padding-left: 2%;
}

.projectsContainer {
    display: flex;
    justify-content: space-evenly;
    margin: 2%;
}

.projectsContainer a {
    background: rgb(44, 44, 44);
    color: #f4f4ff;
    text-decoration: none;
    padding: 0% 3%;
    border-radius: 5px;
}

.projectsContainer a:hover {
    box-shadow: 2.5px 2.5px #888888;
}

/* Media Queries */

@media (max-width: 800px) {
  .projectsContainer {
      display: flex;
      flex-direction: column;
      text-align: center;
  }

  .projectsContainer a {
    margin: 2% 25%;
    max-width: 40%;
  }
}

@media (max-width: 550px) {
    .projectsContainer a {
        max-width: 50%;
    }
}